export default {

    state: {
        user: {},
        authorized: false,
        alert: {
            status: false,
            text: '',
            color: ''
        },
        alertTimeout: null
    },


    actions: {

        request({dispatch, rootState}, data) {
            if (data instanceof FormData) {
                if (!data.has('module'))
                    data.append('module', 'Main');
                if (!data.has('clientId'))
                    data.append('clientId', rootState.main.clientId)
                return fetch('', {
                    method: 'POST',
                    body: data
                }).then(response => {
                    return dispatch('handleResponse', response);
                });
            }

            if (!data.module)
                data.module = 'Main';
            if (!data.clientId)
                data.clientId = rootState.main.clientId;
            return fetch('', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(data)
            }).then(response => {
                return dispatch('handleResponse', response);
            });
        },

        handleResponse({commit}, response) {
            return response.json().then(
                answer => {
                    if (response.ok) return answer;

                    if (response.status == 401) {
                        commit('authorized', false);
                        answer = 'Ошибка авторизации';
                    }

                    if (typeof answer != 'string')
                        answer = 'Возникла ошибка. Попробуйте позже';

                    throw new Error(answer);
                },
                () => {
                    throw new Error('Возникла ошибка. Попробуйте позже');
                }
            );
        },

        alert({state, commit}, {text, color, timeout} = {}) {
            clearTimeout(state.alertTimeout);

            let alert = {};
            alert.status = !!text;
            alert.text = text || state.alert.text;
            alert.color = color || state.alert.color;
            if (timeout === undefined) timeout = 7000;

            commit('alert', alert);

            if (timeout)
                state.alertTimeout = setTimeout(() => {
                    commit('alert', {
                        status: false,
                        text: state.alert.text,
                        color: state.alert.color
                    });
                }, timeout);

            return true;
        }
    },


    mutations: {
        authorized(state, payload) {
            state.authorized = payload
        },

        user(state, payload) {
            state.user = Object.assign({}, payload)
        },

        alert(state, payload) {
            state.alert = payload
        }
    }
}