<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            app
        >
            <v-list
                nav
                dense
                class="fill-height d-flex flex-column justify-space-between"
            >
                <v-list-item-group
                    v-model="selectedItem"
                    color="green"
                >
                    <template v-if="clientId">
                        <div
                            v-if="clients.length == 1"
                            class="mb-5 mt-1 px-3 py-2 text-body-2 elevation-4 rounded"
                            style="color:rgba(0,0,0,.87);"
                        >
                            {{ clients[0].login }}
                        </div>
                        <v-select
                            v-else
                            :value="clientId"
                            :items="clients"
                            @input="selectInput"
                            item-value="id"
                            item-text="login"
                            solo
                            dense
                            hide-details
                            color="green"
                            item-color="green"
                            class="mb-5 mt-1 text-body-2 elevation-2"
                        ></v-select>
                    </template>

                    <template v-for="item in menu">

                        <v-list-item
                            v-if="item.url"
                            :to="item.url"
                        >
                            <v-list-item-icon v-if="item.icon">
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                            <v-badge
                                v-if="item.badge"
                                right
                                offset-x="25"
                                offset-y="10"
                                :color="item.badgeColor"
                                :content="item.badge"
                            ></v-badge>
                        </v-list-item>

                        <v-list-group v-if="item.children">
                            <template v-slot:activator>
                                <v-list-item-icon v-if="item.icon">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ item.name }}
                                </v-list-item-title>
                                <v-badge
                                    v-if="item.badge"
                                    right
                                    offset-x="-5"
                                    offset-y="10"
                                    :color="item.badgeColor"
                                    :content="item.badge"
                                ></v-badge>
                            </template>
                            <v-list-item
                                v-for="child in item.children"
                                :to="child.url"
                            >
                                <v-list-item-icon v-if="child.icon">
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ child.name }}
                                </v-list-item-title>
                                <v-badge
                                    v-if="child.badge"
                                    right
                                    offset-x="25"
                                    offset-y="10"
                                    :color="child.badgeColor"
                                    :content="child.badge"
                                ></v-badge>
                            </v-list-item>
                        </v-list-group>

                    </template>
                </v-list-item-group>

                <v-list-item-group color="green">
                    <v-list-item @click="logOut">
                        <v-list-item-icon>
                            <v-icon>mdi-exit-to-app</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Выйти
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            :color="barColor"
            dark
            dense
        >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div class="text-h6 px-4">{{ $route.name }}</div>
        </v-app-bar>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex"

export default {

    data: () => ({
        drawer: null,
        selectedItem: 0
    }),

    computed: {
        ...mapState({
            version: state => state.main.version,
            role: state => state.common.user.role,
            clients: state => state.main.clients,
            clientId: state => state.main.clientId,
            modules: state => state.main.modules,
            actualTasksCount: state => state.osp.actualTasksCount,
            projectQuestionsCount: state => state.osp.projectQuestionsCount,
            unreadMessagesCount: state => state.chat.unreadMessagesCount,
            fillingCount: state => state.workspace.fillingCount
        }),

        menu() {
            let menu = []

            menu.push({
                name: 'Welcome',
                url: '/welcome',
                icon: 'mdi-home'
            })

            if (this.modules.includes('Chat'))
                menu.push({
                    name: 'wiChat',
                    url: '/chat',
                    icon: 'mdi-message',
                    badge: this.unreadMessagesCount,
                    badgeColor: 'purple lighten-1'
                })

            if (this.modules.includes('Osp'))
                menu.push({
                    name: 'Создание сайта',
                    icon: 'mdi-web-plus',
                    badge: (this.actualTasksCount + this.projectQuestionsCount),
                    children: [
                        {
                            name: 'Актуальные задачи',
                            url: '/osp/actual',
                            icon: 'mdi-clipboard-list',
                            badge: this.actualTasksCount,
                            badgeColor: 'purple lighten-1'
                        },
                        {
                            name: 'Данные по проекту',
                            url: '/osp/projectData',
                            icon: 'mdi-space-invaders',
                            badge: this.projectQuestionsCount,
                            badgeColor: 'purple lighten-1'
                        }
                    ]
                })

            if (this.modules.includes('Workspace'))
                menu.push({
                    name: 'WorkSpace',
                    url: '/workspace',
                    icon: 'mdi-space-invaders',
                    badge: this.fillingCount,
                    badgeColor: 'purple lighten-1'
                })

            if (this.role !== 'minor')
                menu.push({
                    name: 'Управление аккаунтом',
                    url: '/management',
                    icon: 'mdi-cog'
                })

            return menu
        },

        barColor() {
            if (this.role == 'ghost') return 'blue-grey'
            if (this.$route.name == 'wiChat') return '#02a884'
            return this.role == 'ghost' ? 'blue-grey' : 'green'
        }
    },

    methods: {

        ...mapActions([
            'changeClient',
            'getClients'
        ]),

        selectInput(clientId) {
            this.getClients()
            this.changeClient(clientId).then(() => {
                this.moduleRedirect()
            })
        },

        moduleRedirect() {
            if (
                this.$route.path == '/chat' &&
                !this.modules.includes('Chat')
            ) this.$router.push('/home')

            if (
                this.$route.path == '/workspace' &&
                !this.modules.includes('Workspace')
            ) {
                if (this.modules.includes('Chat'))
                    this.$router.push('/chat')
                else
                    this.$router.push('/home')
            }
        },

        logOut() {
            return this.$store.dispatch('request', {
                controller: 'Auth',
                action: 'logOut'
            }).then(answer => {
                if (answer) this.$store.commit('authorized', false)
            });
        }
    }

}
</script>