export default {

    state: {
        version: '',
        clientId: null,
        clients: {},
        login: '',
        contact: {},
        manager: {},
        readComments: [],
        modules: [],
        projectForTasksId: ''
    },


    actions: {

        // Получить версию приложения
        getVersion({dispatch, commit}) {
            return dispatch('request', {
                controller: 'Controller',
                action: 'getVersion'
            }).then(version => {
                commit('version', version)
            })
        },

        // Получить список клиентов, прикреплённых к пользователю
        getClients({dispatch, commit}) {
            return dispatch('request', {
                controller: 'Client',
                action: 'getClients'
            }).then(clients => {
                if (!Array.isArray(clients)) return
                let clientId = localStorage.getItem('clientId')
                if (
                    clients.length &&
                    clientId &&
                    !clients.find(client => client.id == clientId)
                ) localStorage.setItem('clientId', clients[0]['id'])
                commit('clients', clients)
            })
        },


        // Получить данные по клиенту из базы
        getClient({dispatch, commit}) {

            let clientId = localStorage.getItem('clientId')

            return dispatch('request', {
                controller: 'Client',
                action: 'getData',
                clientId
            }).then(answer => {

                if (answer?.id === undefined) return Promise.resolve('Не удалось получить данные о клиенте')

                if (!clientId) localStorage.setItem('clientId', answer.id)

                commit('clientId', answer.id)

                commit('login', answer.login)

                commit('contact', answer.contact)

                commit('manager', answer.manager)

                commit('projectForTasksId', answer['project_for_tasks_id'])

                commit('readComments', answer['read_comments'])

                let modules = ['Main']

                if (answer['module_workspace']) {
                    commit('questionnaireFolders', answer['module_workspace']['folders'])
                    commit('handbooks', answer['module_workspace']['handbooks'])
                    commit('infoHandbooks', answer['module_workspace']['info_handbooks'])
                    commit('defaultDevelopments', answer['module_workspace']['default_developments'])
                    commit('progress', answer['module_workspace']['progress'])
                    commit('fillingCount', answer['module_workspace']['filling_count'])
                    modules.push('Workspace')
                }

                if (answer['module_chat']) {
                    commit('chatMessages', answer['module_chat']['messages'])
                    commit('unreadMessagesCount', answer['module_chat']['unread_messages_count'])
                    modules.push('Chat')
                }

                commit('modules', modules)

                return Promise.resolve()

            })

        },


        // Поменять текущего клиента и получить данные
        changeClient({dispatch}, clientId) {
            localStorage.setItem('clientId', clientId)
            return dispatch('getClient')
        },


        // Получить данные авторизованного пользователя
        getUser({dispatch, commit}) {
            return dispatch('request', {
                controller: 'Auth',
                action: 'getUser'
            }).then(answer => {
                commit('user', answer)
            }).catch(e => {
                console.error(e.message)
            })
        },


        // Отправить в задачу комментарий
        sendComment({state, dispatch}, {taskId, comment = '', files = []}) {
            let formData = new FormData()
            formData.append('controller', 'ToPlanfix')
            formData.append('action', 'sendComment')
            formData.append('managerId', state.manager.id)
            formData.append('contactId', state.contact.userid)
            formData.append('taskId', taskId)
            formData.append('comment', comment)
            files.forEach((file, key) => {
                formData.append('file' + key, file)
            })
            return dispatch('request', formData)
        },


        savePushToken({dispatch}, token) {
            return dispatch('request', {
                controller: 'User',
                action: 'savePushToken',
                token: token
            })
        },


        // Отправить в планфикс запрос на изменение статуса задачи
        changeTaskStatus({dispatch}, {taskId, status}) {
            return dispatch('request', {
                controller: 'ToPlanfix',
                action: 'changeTaskStatus',
                taskId: taskId,
                status: status
            })
        },


        // Добавить новые прочтённые комментарии
        addReadComments({dispatch}, comments) {
            return dispatch('request', {
                controller: 'Client',
                action: 'addReadComments',
                comments
            })
        }

    },


    mutations: {

        version(state, payload) {
            state.version = payload
        },

        clients(state, payload) {
            state.clients = payload.slice()
        },

        clientId(state, payload) {
            state.clientId = payload
        },

        login(state, payload) {
            state.login = payload
        },

        contact(state, payload) {
            state.contact = Object.assign({}, payload)
        },

        manager(state, payload) {
            state.manager = Object.assign({}, payload)
        },

        projectForTasksId(state, payload) {
            state.projectForTasksId = payload
        },

        readComments(state, payload) {
            state.readComments = payload.slice()
        },

        modules(state, payload) {
            state.modules = payload
        }

    }

}