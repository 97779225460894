<template>
    <v-container class="fill-height text-center">
        <v-row>
            <v-col>
                <v-progress-circular
                    size="100"
                    color="green"
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {}
</script>