export default {

    state: {
        handbooks: [],
        infoHandbooks: [],
        defaultDevelopments: [],
        progress: [],
        questionnaireFolders: [],
        fillingCount: 0,
        gettingProgressCycle: false,
        progressUpdatedAt: 0
    },

    actions: {
        refreshWorkspace({dispatch, commit}) {
            let clientId = localStorage.getItem('clientId')
            return dispatch('request', {
                module: 'Workspace',
                controller: 'Workspace',
                action: 'getWorkspace',
                clientId
            }).then(answer => {
                commit('questionnaireFolders', answer['folders'])
                commit('handbooks', answer['handbooks'])
                commit('infoHandbooks', answer['info_handbooks'])
                commit('defaultDevelopments', answer['default_developments'])
                commit('progress', answer['progress'])
                commit('fillingCount', answer['filling_count'])
            })
        },

        getProgress({dispatch, commit}) {
            return dispatch('request', {
                'module': 'Workspace',
                'controller': 'Workspace',
                'action': 'getProgress'
            }).then(answer => {
                commit('progress', answer['progress'])
                commit('progressUpdatedAt', answer['updated'])
            });
        }
    },

    mutations: {
        handbooks(state, payload) {
            state.handbooks = payload
        },

        infoHandbooks(state, payload) {
            state.infoHandbooks = payload
        },

        defaultDevelopments(state, payload) {
            state.defaultDevelopments = payload
        },

        progress(state, payload) {
            state.progress = payload
        },

        questionnaireFolders(state, payload) {
            state.questionnaireFolders = payload
        },

        fillingCount(state, payload) {
            state.fillingCount = payload
        },

        gettingProgressCycle(state, payload) {
            state.gettingProgressCycle = payload
        },

        progressUpdatedAt(state, payload) {
            if (payload) state.progressUpdatedAt = payload
        }
    }

}