import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        beforeEnter: (to, from, next) => {
            if (store.state.main.modules?.includes('Chat'))
                next('/chat')
            else if (store.state.main.modules?.includes('Main'))
                next('/home')
            else
                next()
        }
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('@/views/Home')
    },
    {
        path: '/auth',
        name: 'Авторизация',
        component: () => import('@/views/Auth')
    },
    {
        path: '/chat',
        name: 'wiChat',
        component: () => import('@/views/Chat')
    },
    {
        path: '/workspace',
        name: 'WorkSpace',
        component: () => import('@/views/WorkSpace')
    },
    {
        path: '/management',
        name: 'Управление аккаунтом',
        component: () => import('@/views/Management')
    },
    // {
    //     path: '/osp',
    //     name: 'Создание сайта',
    //     redirect: {
    //         name: 'Актуальные задачи',
    //     }
    // },
    // {
    //     path: '/osp/actual',
    //     name: 'Актуальные задачи',
    //     component: () => import('@/views/Osp')
    // },
    // {
    //     path: '/osp/finished',
    //     name: 'Закрытые вопросы',
    //     component: () => import('@/views/Osp')
    // },
    // {
    //     path: '/osp/task/:id',
    //     name: 'Детальная страница задачи',
    //     component: () => import('@/views/Task')
    // },
    // {
    //     path: '/osp/projectData',
    //     name: 'Данные по проекту',
    //     component: () => import('@/views/ProjectData')
    // },
    // {
    //     path: '/direct',
    //     name: 'Директ',
    //     component: () => import('@/views/Direct')
    // },
    {
        path: '*',
        redirect: '/'
    }
]

const scrollBehavior = function (to, from, savedPosition) {
    if (savedPosition)
        return savedPosition
    else
        return {x: 0, y: 0}
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
})

router.beforeEach((to, from, next) => {
    if (store.state.common.authorized)
        store.dispatch('getClient')
    next()
})

export default router
