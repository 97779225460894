<template>
    <v-app id="app">

        <navigation v-if="authorized && !loading"></navigation>

        <v-main>
            <loading v-if="loading"></loading>
            <router-view v-else></router-view>
        </v-main>

        <v-snackbar
            v-if="authorized"
            :value="alert.status"
            :color="alert.color"
            :timeout="-1"
            transition="slide-y-reverse-transition"
            max-width="100vw"
            min-width="300px"
        >

            <span class="text-h6">{{ alert.text }}</span>

            <template v-slot:action="{ attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    @click="closeAlert"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import loading from '@/components/Loading'
import navigation from '@/components/Navigation'
import {mapState, mapActions, mapMutations} from 'vuex'

export default {

    components: {
        loading,
        navigation
    },


    data: () => ({
        loading: true,
        watchAuthorized: false
    }),


    computed: {
        ...mapState({
            authorized: state => state.common.authorized,
            alert: state => state.common.alert,
            modules: state => state.main.modules
        })
    },


    watch: {
        authorized() {
            if (this.watchAuthorized) this.redirect()
        }
    },


    created() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)

        window.addEventListener('resize', e => {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        })

        this.initApp()
    },


    methods: {

        ...mapActions([
            'request',
            'getVersion',
            'getUser',
            'getClients',
            'getClient'
        ]),

        ...mapMutations({'changeAuthorized': 'authorized'}),

        async initApp() {
            let url = new URL(location.href)
            let key = url.searchParams.get('key')

            let authorized = await this.request({
                controller: 'Auth',
                action: 'authorize',
                key
            })

            this.changeAuthorized(authorized)

            if (authorized) {
                try {
                    await this.getUser()
                    await this.getClients()
                    await this.getClient()
                    await this.getVersion()
                } catch (e) {
                    console.error(e)
                }
            }

            this.redirect()
            setTimeout(() => {
                this.loading = false
                this.watchAuthorized = true
            }, 333)
        },

        redirect() {
            if (!this.authorized && this.$route.name !== 'Авторизация') {
                this.$router.push('/auth')
                return
            }

            if (
                this.authorized &&
                this.$route.name === 'Авторизация' ||
                this.$route.path === '/'
            ) {
                if (this.modules.includes('Chat'))
                    this.$router.push('/chat')
                else
                    this.$router.push('/home')
                return
            }
        },

        closeAlert() {
            this.$store.dispatch('alert')
        }

    }
}
</script>

<style lang="scss">

html, body {
    overscroll-behavior: contain;
}

.v-application--wrap {
    min-height: auto;
    height: calc(var(--vh, 1vh) * 100);
}

.v-main {
    height: 100%;
}

a {
    text-decoration: none;
}

p {
    margin-bottom: 0 !important;
}

blockquote {
    border-left: 2px solid #bbb;
    padding-left: 10px;
    margin: 13px 8px;
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/img/chat/bg-light.png');
    background-repeat: repeat-x;
    background-size: contain;
}

.break {
    word-break: break-word;
}

.v-textarea label {
    white-space: normal !important;
    height: auto !important;
}

.instruction-text h3 {
    margin-top: 10px;
}

.actual-task-text {
    overflow: hidden;
}

.actual-task-text * {
    white-space: normal !important;
}

.black-color {
    color: rgba(0, 0, 0, 0.87);
}

</style>