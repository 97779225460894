import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import common from './common'
import main from './main'
import osp from './osp'
import chat from './chat'
import workspace from './workspace'

export default new Vuex.Store({
    modules: {
        common,
        main,
        osp,
        chat,
        workspace
    }
})