import Vue from 'vue'

export default {

    state: {
        messages: [],
        unreadMessagesCount: 0,
        gettingChat: false,
        updatingChat: false,
        gettingChatCycle: false,
        inputText: ''
    },

    actions: {

        updateChat({state, commit, dispatch}, collectData = true) {
            if (state.updatingChat) return true
            commit('updatingChat', true)
            return dispatch('request', {
                module: 'Chat',
                controller: 'Chat',
                action: 'updateChat',
                collectData
            }).finally(() => {
                commit('updatingChat', false)
            })
        },

        getChat({state, dispatch, commit, rootState}, lastMessageId) {
            if (state.gettingChat) return true
            commit('gettingChat', true)
            return dispatch('request', {
                module: 'Chat',
                controller: 'Chat',
                action: 'getMessages',
                last_message_id: lastMessageId
            }).then(answer => {
                if (answer === true) return true
                commit('chatMessages', answer['messages'])
            }).finally(() => {
                commit('gettingChat', false)
            })
        }

    },

    mutations: {

        inputText(state, payload) {
            state.inputText = payload
        },

        gettingChatCycle(state, payload) {
            state.gettingChatCycle = payload
        },

        updatingChat(state, payload) {
            state.updatingChat = payload
        },

        gettingChat(state, payload) {
            state.gettingChat = payload
        },

        unreadMessagesCount(state, count) {
            state.unreadMessagesCount = count
        },

        chatMessages(state, messages) {

            // Удалить сообщения, которых нет в планфиксе
            for (let i = 0; i < state.messages.length; i++) {
                let index = messages.findIndex(newMessage => newMessage.id == state.messages[i].id)
                if (index == -1) {
                    state.messages.splice(i, 1)
                    i--
                }
            }

            for (let i = 0; i < messages.length; i++) {
                let index = state.messages.findIndex(stateMessage => stateMessage.id == messages[i].id)
                // Добавить новое сообщение из планфикса
                if (index == -1) {

                    messages[i].show = false

                    if (state.messages.length) {
                        let insertIndex = 0
                        if (messages[i].timestamp >= state.messages[state.messages.length - 1].timestamp)
                            insertIndex = state.messages.length
                        else
                            while (messages[i].timestamp >= state.messages[insertIndex].timestamp)
                                insertIndex++

                        state.messages.splice(insertIndex, 0, messages[i])

                    } else {
                        state.messages.push(messages[i])
                    }

                    // Обновить присутствующее в чате сообщение
                } else {
                    if (state.messages[index].html != messages[i].html) Vue.set(state.messages[index], 'html', messages[i].html)

                    // Удалить файлы из сообщения, если в планфиксе их больше нет
                    if (state.messages[index].files && messages[i].files) for (let j = 0; j < state.messages[index].files.length; j++) {
                        let fileIndex = messages[i].files.findIndex(file => file.id == state.messages[index].files[j].id)
                        if (fileIndex == -1) {
                            state.messages[index].files.splice(j, 1)
                            j--
                        }
                    } else Vue.set(state.messages[index], 'files', [])

                    // Добавить в сообщение файлы из планфикса
                    if (messages[i].files) {
                        messages[i].files.forEach(file => {
                            let fileIndex = state.messages[index].files.findIndex(stateFile => stateFile.id == file.id)
                            if (fileIndex == -1) state.messages[index].files.push(file)
                        })
                    }
                }
            }
        }
    }
}
