export default {

    state: {
        project: {},
        tasks: [],
        actualTasksCount: 0,
        projectQuestions: [],
        projectQuestionsCount: 0,
        tasksReceived: false,
        communicationTaskId: '',
        coordinationTaskId: ''
    },


    getters: {
        coordinator: state => {
            return state.project.customData.customValue.find(item => item.field.id == '59926')['value']
        }
    },


    actions: {

        setOsp({commit, rootState}, ospData) {

            commit('project', ospData.project)

            commit('projectQuestions', ospData['project_questions'])

            commit('coordinationTaskId', ospData['coordination_task_id'])

            commit('communicationTaskId', ospData['communication_task_id'])

            commit('setTasks', {tasks: ospData.tasks, main: rootState.main})

        },


        // Перевести в базе задачу в статус выполненная вручную
        completeTask({dispatch}, taskId) {
            return dispatch('request', {
                module: 'Osp',
                controller: 'Osp',
                action: 'completeTask',
                taskId: taskId
            })
        },


        // Обновить в базе данные по конкретной задаче
        updateTaskOsp({state, dispatch}, taskId) {
            return dispatch('request', {
                module: 'Osp',
                controller: 'Osp',
                action: 'updateTask',
                taskId: taskId
            })
        }

    },


    mutations: {

        project(state, payload) {
            state.project = Object.assign({}, payload)
        },

        coordinationTaskId(state, payload) {
            state.coordinationTaskId = payload
        },

        communicationTaskId(state, payload) {
            state.communicationTaskId = payload
        },

        projectQuestions(state, projectQuestions) {

            state.projectQuestions = projectQuestions.slice()

            let projectQuestionsCount = 0
            projectQuestions.forEach((section) => {
                section.questions.forEach(question => {
                    if (question.status == 'wait')
                        projectQuestionsCount++
                })
            })

            state.projectQuestionsCount = projectQuestionsCount
        },

        setTasks(state, {tasks, main}) {

            tasks = JSON.parse(JSON.stringify(tasks))

            let actualTasksCount = 0

            // Убираем задачи с лишними статусами
            tasks.forEach((task, index) => {
                if (
                    task.status !== '3' &&  // Завершённая
                    task.status !== '6' &&  // Выполненная
                    task.status !== '241'   // Ждём ответа клиента
                )
                    tasks.splice(index, 1)
            })

            // Определяем, является ли задача выполненной или завершённой
            tasks.forEach(task => {
                task.finished = task.status !== '241'
                if (!task.finished) actualTasksCount++
            })

            state.actualTasksCount = actualTasksCount

            // Устанавливаем тип задачи - согласование или вопрос
            tasks.forEach((task) => {
                task.customData.customValue.forEach(custom => {
                    if (custom.field.id === '60088' && custom.value === 'Согласование')
                        task.cardType = 'agreement'

                    if (custom.field.id === '60088' && custom.value === 'Вопрос или запрос')
                        task.cardType = 'question'
                })

                if (task.cardType === undefined)
                    task.cardType = 'question'
            })

            // Выбираем комментарии, относящиеся к контакту
            // Ищем действие, являющееся созданием задачи
            tasks.forEach(task => {
                task.comments = []
                task.actions.forEach(action => {

                    if (action.type !== undefined && action.type === 'TASKCREATED')
                        task.taskCreatedAction = action

                    // Проверяем, является ли пользователь автором действия
                    action.own = action.owner !== undefined &&
                        action.owner.id !== undefined &&
                        action.owner.id === main.contact.userid

                    if (action.own && (action.type === 'ACTION' || action.type === 'COMMENT')) {
                        task.comments.push(action)
                        return
                    }

                    if (action.notifiedList !== undefined && action.notifiedList.user !== undefined) {
                        if (Array.isArray(action.notifiedList.user)) {
                            action.notifiedList.user.forEach(user => {
                                if (user.id === main.contact.userid)
                                    task.comments.push(action)
                            })
                        } else {
                            if (action.notifiedList.user.id === main.contact.userid)
                                task.comments.push(action)
                        }
                    }
                })
            })


            // Распихиваем файлы в задачу и в комментарии
            tasks.forEach(task => {

                if (task.taskCreatedAction !== undefined)
                    getFilesData(task.taskCreatedAction)

                if (task.comments.length)
                    task.comments.forEach(comment => {
                        getFilesData(comment)
                    })

                function getFilesData(comment) {
                    if (comment.files !== undefined && comment.files.file !== undefined) {
                        if (Array.isArray(comment.files.file))
                            comment.files.file.forEach(file => {
                                file.data = findFileById(file.id)
                            })
                        else comment.files.file.data = findFileById(comment.files.file.id)
                    }
                }

                function findFileById(fileId) {
                    let result = null
                    if (task.files !== undefined && Array.isArray(task.files))
                        task.files.forEach(file => {
                            if (file.id === fileId) result = Object.assign({}, file)
                        })
                    return result
                }
            })

            // Считаем непрочтённые комментарии
            tasks.forEach(task => {
                let unreadComments = task.comments.length
                task.comments.forEach(comment => {

                    if (comment.own) {
                        unreadComments--
                        return
                    }

                    main.readComments.forEach(readComment => {
                        if (comment.id === readComment) unreadComments--
                    })
                })
                task.unreadComments = unreadComments
            })

            state.tasks = tasks
            state.tasksReceived = true
        }
    }

}